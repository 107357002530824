import {thunk} from 'redux-thunk';
import {
    applyMiddleware as applyReduxMiddleware,
    createStore as createReduxStore,
    Middleware as ReduxMiddleware,
    Store as ReduxStore
} from 'redux';
import Reducers from './reducers';
import { 
    Producer, 
    Peer,
    Consumer,
    Notification,
    ProducerID,
    PeerID,
    ConsumerID,
    Me
} from '../types';
import { RoomState } from './types';

export interface Room {
    url: string;
    state: RoomState,
    activeSpeaerId: PeerID|null
}

export type Producers = Map<ProducerID, Producer>;

export type Peers = Map<PeerID, Peer>;

export type Consumers = Map<ConsumerID, Consumer>;

export type Notifications = Array<Notification>;

export interface RootState {
    room: Room;
    me: Me;
    producers: Producers;
    peers: Peers;
    consumers: Consumers;
    notifications: Notifications;
}

export const createStore = (): ReduxStore<RootState> => {
    const reduxMiddlewares: Array<ReduxMiddleware> = [thunk];
    if (process.env.NODE_ENV === 'development') {
        // const reduxLogger = createReduxLogger({
        //     duration: true,
        //     timestamp: false,
        //     level: 'log',
        //     logErrors: true
        // });
        // reduxMiddlewares.push(reduxLogger);
    }

    const store = createReduxStore(
        Reducers,
        undefined,
        applyReduxMiddleware(...reduxMiddlewares)
    );
    (window as any).STORE = store;
    return store;
}
