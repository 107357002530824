import { Me } from '../../types';
import {
    KonferenzAction,
    SetAudioMutedStateAction,
    SetAudioOnlyInProgressAction,
    SetAudioOnlyStateAction,
    SetCanChangeWebcamAction,
    SetDisplayNameAction,
    SetMeAction,
    SetMediaCapabilitiesAction,
    SetMicInProgressAction,
    SetRoomStateAction,
    SetWebcamInProgressAction,
    SET_AUDIO_MUTED_STATE,
    SET_AUDIO_ONLY_IN_PROGRESS,
    SET_AUDIO_ONLY_STATE,
    SET_CAN_CHANGE_WEBCAM,
    SET_DISPLAY_NAME,
    SET_ME,
    SET_MEDIA_CAPABILITIES,
    SET_MIC_IN_PROGRESS,
    SET_ROOM_STATE,
    SET_WEBCAM_IN_PROGRESS
} from '../types';


const initialState: Me = {
    id: '',
    displayName: '',
    displayNameSet: false,
    canSendMic: false,
    canSendWebcam: false,
    canChangeWebcam: false,
    webcamInProgress: false,
    micInProgress: false,
    audioOnlyInProgress: false,
    audioMuted: false
}

const me = (state: Me = initialState, konferenzAction: KonferenzAction): Me => {
    switch (konferenzAction.type) {
        case SET_ROOM_STATE: {
            const action = (konferenzAction as SetRoomStateAction);
            if (action.state === 'closed') {
                return {
                    ...state,
                    webcamInProgress: false
                }
            } else {
                return state;
            }
        }

        case SET_ME: {
            const action = konferenzAction as SetMeAction;
            return {
                ...state,
                id: action.me.id,
                displayName: action.me.displayName
            };
        }

        case SET_MEDIA_CAPABILITIES: {
            const action = konferenzAction as SetMediaCapabilitiesAction;
            return {
                ...state,
                canSendMic: action.canSendMic,
                canSendWebcam: action.canSendWebcam
            }
        }

        case SET_CAN_CHANGE_WEBCAM: {
            const action = konferenzAction as SetCanChangeWebcamAction;
            return {
                ...state,
                canChangeWebcam: action.canChangeWebcam
            };
        }

        case SET_WEBCAM_IN_PROGRESS: {
            const action = konferenzAction as SetWebcamInProgressAction;
            return {
                ...state,
                webcamInProgress: action.webcamInProgress
            };
        }

        case SET_MIC_IN_PROGRESS: {
            const action = konferenzAction as SetMicInProgressAction;
            return {
                ...state,
                micInProgress: action.micInProgress
            };
        }

        case SET_DISPLAY_NAME: {
            const action = konferenzAction as SetDisplayNameAction;
            return {
                ...state,
                displayName: action.displayName || state.displayName,
                displayNameSet: true
            };
        }

        case SET_AUDIO_ONLY_STATE: {
            const action = konferenzAction as SetAudioOnlyStateAction;
            return {
                ...state,
                audioOnlyInProgress: action.audioOnly
            };
        }

        case SET_AUDIO_ONLY_IN_PROGRESS: {
            const action = konferenzAction as SetAudioOnlyInProgressAction;
            return {
                ...state,
                audioOnlyInProgress: action.audioOnlyInProgress
            }
        }

        case SET_AUDIO_MUTED_STATE: {
            const action = konferenzAction as SetAudioMutedStateAction;
            return {
                ...state,
                audioMuted: action.audioMuted
            };
        }

        default:
            return state;
    }
}

export default me;
