import React, { createRef } from 'react';
import Logger from '../lib/Logger';
import { IPeer } from '../types';
import classNames from 'classnames';
import hark, { Harker } from 'hark';
import '../styles/PeerView.scss';

const log = new Logger('PeerView');
log.debug('PeerView');

type Props = {
    isMe?: boolean,
    videoMirrored?: boolean,
    peer: IPeer,
    audioTrack: MediaStreamTrack | undefined,
    videoTrack: MediaStreamTrack | undefined,
    audioMuted?: boolean,
    directAudio?: boolean,
    videoVisible: boolean,
    videoLayer: {
        spatialLayer?: number,
        temporalLayer?: number
    }
};

type State = {
    audioVolume: number,
    videoResolutionWidth: number | null,
    videoResolutionHeight: number | null,
    videoCanPlay: boolean,
    videoElemPaused: boolean
}

export default class PeerView extends React.Component<Props, State>
{
    protected audioTrack: MediaStreamTrack | undefined;

    protected videoTrack: MediaStreamTrack | undefined;

    protected hark: Harker | null;

    protected videoResolutionTimer: NodeJS.Timeout | null;

    protected videoElem: React.RefObject<HTMLVideoElement>;

    protected audioElem: React.RefObject<HTMLAudioElement>;

    constructor(props: Props) {
        super(props);
        this.state = {
            audioVolume: 0,
            videoResolutionWidth: null,
            videoResolutionHeight: null,
            videoCanPlay: false,
            videoElemPaused: false
        }
        this.hark = null;
        this.videoResolutionTimer = null;
        this.videoElem = createRef<HTMLVideoElement>();
        this.audioElem = createRef<HTMLAudioElement>();
    }

    render() {
        const {
            isMe,
            videoMirrored,
            peer,
            audioMuted,
            videoVisible
        } = this.props;

        const {
            audioVolume,
        } = this.state;

        return (
            <div data-component='PeerView' className={`video-${videoVisible ? 'visible' : 'hidden'}`}>
                <div className='info'>
                    <div className='icons'></div>
                    <div className={classNames('peer', { 'is-me': isMe })}>
                        <span className='display-name'>{peer.displayName}</span>
                    </div>
                </div>

                <video
                    ref={this.videoElem}
                    className={classNames({
                        'is-me': isMe,
                        'video-mirrored': videoMirrored,
                        hidden: !videoVisible,
                    })}
                    autoPlay
                    playsInline
                    muted
                    controls={false}
                />
                <audio
                    ref={this.audioElem}
                    autoPlay
                    playsInline
                    muted={isMe || audioMuted}
                    controls={false}
                />

                <div className='volume-container'>
                    <div className={`bar level-${audioVolume}`}/>
                </div>

                <div className={classNames('video-elem-paused', {
                    hidden: this.props.videoVisible
                })}/>
            </div>
        );
    }

    componentDidMount() {
        const { audioTrack, videoTrack } = this.props;
        this.setTracks(audioTrack, videoTrack);
    }

    componentWillUnmount() {
        this.hark && this.hark.stop();
        this.videoResolutionTimer && clearInterval(this.videoResolutionTimer);
        if (this.videoElem.current) {
            this.videoElem.current.oncanplay = null;
            this.videoElem.current.onplay = null;
            this.videoElem.current.onpause = null;
        }
    }

    componentDidUpdate() {
        const { directAudio, audioTrack, videoTrack } = this.props;
        this.setTracks(directAudio ? undefined : audioTrack, videoTrack);
    }

    protected setTracks(audioTrack: MediaStreamTrack | undefined, videoTrack: MediaStreamTrack | undefined) {
        if (this.audioTrack === audioTrack && this.videoTrack === videoTrack) {
            return;
        }

        log.debug('setTracks(audioTrack = %o, videoTrack = %o', audioTrack, videoTrack);

        this.audioTrack = audioTrack;
        this.videoTrack = videoTrack;

        this.hark && this.hark.stop();
        this.videoResolutionTimer && this.stopVideoResolution();

        const audioElem = this.audioElem.current;
        const videoElem = this.videoElem.current;

        if (audioElem && audioTrack) {
            const stream = new MediaStream();
            stream.addTrack(audioTrack);
            audioElem.srcObject = stream;
            audioElem.play().catch(error => {
                log.error('Cannot play audio element: %o', error);
            });
            this.runHark(stream);
        } else if (audioElem) {
            audioElem.srcObject = null;
        }

        if (videoElem && videoTrack) {
            const stream = new MediaStream();
            stream.addTrack(videoTrack);
            videoElem.srcObject = stream;
            videoElem.oncanplay = () => this.setState({ videoCanPlay: true });
            videoElem.onplay = () => {
                this.setState({ videoElemPaused: false });
                audioElem?.play().catch(error => log.error('Cannot play audio element: %o', error));
            }
            videoElem.onpause = () => this.setState({ videoElemPaused: true });

            videoElem.play().catch(error => log.error('Cannot play video element: %o', error));
            this.startVideoResolution();
        } else if (videoElem) {
            videoElem.srcObject = null;
        }
    }

    protected runHark(stream: MediaStream) {
        log.debug('runHark()');
        if (!stream.getAudioTracks()[0]) {
            throw new Error('runHark(): The given stream has no audio tracks.');
        }

        this.hark = hark(stream, { play: false });
        this.hark.on('volume_change', (volume: number, threshold: number) => {
            let audioVolume = Math.round(Math.pow(25, volume / 85) * 25);
            if (audioVolume === 1) {
                audioVolume = 0;
            }

            if (audioVolume !== this.state.audioVolume) {
                this.setState({ audioVolume });
            }
        });
    }

    protected startVideoResolution() {
        log.debug('startVideoResolution()');
        this.videoResolutionTimer = setInterval(() => {
            const { videoResolutionWidth, videoResolutionHeight } = this.state;
            const videoElem = this.videoElem.current;

            if (videoElem && (videoElem.videoWidth !== videoResolutionWidth || videoElem.videoHeight !== videoResolutionHeight)) {
                this.setState({
                    videoResolutionWidth: videoElem.videoWidth,
                    videoResolutionHeight: videoElem.videoHeight
                });
            }
        }, 500);
    }

    protected stopVideoResolution() {
        log.debug('stopVideoResolution()');
        this.videoResolutionTimer && clearInterval(this.videoResolutionTimer);
        this.videoResolutionTimer = null;

        this.setState({
            videoResolutionWidth: null,
            videoResolutionHeight: null
        });
    }
};
