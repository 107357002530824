import * as State from '../store';
import * as Type from '../types';

const initialState: State.Consumers = new Map();

const consumers = (state: State.Consumers = initialState, konferenzAction: Type.KonferenzAction): State.Consumers => {
    switch (konferenzAction.type) {
        case Type.SET_ROOM_STATE: {
            const action = konferenzAction as Type.SetRoomStateAction;
            if (action.state === 'closed') {
                return new Map();
            } else {
                return state;
            }
        }

        case Type.ADD_CONSUMER: {
            const action = konferenzAction as Type.AddConsumerAction;
            const consumer = action.consumer;
            const newState = new Map(state);
            newState.set(consumer.id, consumer);
            return newState;
        }

        case Type.REMOVE_CONSUMER: {
            const action = konferenzAction as Type.RemoveConsumerAction;
            const newState = new Map(state);
            newState.delete(action.consumerId);
            return newState;
        }

        case Type.SET_CONSUMER_PAUSED: {
            const action = konferenzAction as Type.SetConsumerPausedAction;
            const consumer = state.get(action.consumerId);
            if (!consumer) {
                return state;
            }

            const newConsumer: typeof consumer = {...consumer};
            if (action.originator === 'local') {
                newConsumer.locallyPaused = true;            
            } else {
                newConsumer.remotelyPaused = true;
            }

            const newState = new Map(state);
            newState.set(consumer.id, newConsumer);
            return newState;
        }

        case Type.SET_CONSUMER_RESUMED: {
            const action = konferenzAction as Type.SetConsumerResumedAction;
            const consumer = state.get(action.consumerId);
            if (!consumer) {
                return state;
            }

            const newConsumer: typeof consumer = {...consumer};
            if (action.originator === 'local') {
                newConsumer.locallyPaused = false;
            } else {
                newConsumer.remotelyPaused = false;
            }

            const newState = new Map(state);
            newState.set(consumer.id, newConsumer);
            return newState;
        }

        case Type.SET_CONSUMER_CURRENT_LAYERS: {
            const action = konferenzAction as Type.SetConsumerCurrentLayersAction;
            const consumer = state.get(action.consumerId);
            if (!consumer) {
                return state;
            }
            
            const newConsumer: typeof consumer = {
                ...consumer,
                currentSpatialLayer: action.spatialLayer,
                currentTemporalLayer: action.temporalLayer,
            };

            const newState = new Map(state);
            newState.set(consumer.id, newConsumer);
            return newState;
        }

        case Type.SET_CONSUMER_PREFERRED_LAYERS: {
            const action = konferenzAction as Type.SetConsumerPreferredLayersAction;
            const consumer = state.get(action.consumerId);
            if (!consumer) {
                return state;
            }
            
            const newConsumer: typeof consumer = {
                ...consumer,
                preferredSpacialLayer: action.spatialLayer,
                preferredTemporalLayer: action.temporalLayer,
            };

            const newState = new Map(state);
            newState.set(consumer.id, newConsumer);
            return newState;
        }

        case Type.SET_CONSUMER_TRACK: {
            const action = konferenzAction as Type.SetConsumerTrackAction;
            const consumer = state.get(action.consumerId);
            if (!consumer) {
                return state;
            }
            
            const newConsumer: typeof consumer = {
                ...consumer,
                track: action.track
            };

            const newState = new Map(state);
            newState.set(consumer.id, newConsumer);
            return newState;
        }

        case Type.SET_CONSUMER_SCORE: {
            const action = konferenzAction as Type.SetConsumerScoreAction;
            const consumer = state.get(action.consumerId);
            if (!consumer) {
                return state;
            }
            
            const newConsumer: typeof consumer = {
                ...consumer,
                score: action.score
            };

            const newState = new Map(state);
            newState.set(consumer.id, newConsumer);
            return newState;
        }
            
        default:
            return state;
    }
}

export default consumers;
