import React, { useEffect } from 'react';
import Logger from '../lib/Logger';
import { RootState } from '../redux/store';
import { connect, ConnectedProps } from 'react-redux';
import Notifications from './Notifications';
import clipboardCopy from 'clipboard-copy';
import { Dispatch } from 'redux';
import { notify } from '../redux/actions';
import i18next from 'i18next';
import Me from './Me';
import Peers from './Peers';
import RoomClient from '../lib/RoomClient';
import RoomContext from '../lib/RoomContext';
import '../styles/Room.scss';

const log = new Logger('Room');
log.debug('Room');

const mapState = (state: RootState) => ({
    room: state.room,
    me: state.me,
    amActiveSpeaker: state.me.id === state.room.activeSpeaerId
});

const mapDispatch = (dispatch: Dispatch) => ({
    notify
});

const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

function Room(props: Props & { roomClient?: RoomClient }) {
    const onRoomLinkCopy = () => {
        props.notify(
            'info',
            i18next.t('Raumlink wurde in die Zwischenablage kopiert.')
        );
    };
    
    useEffect(() => {
        props.roomClient?.join()
    }, [props.roomClient]);

    return (
      <div data-component="Room">
        <Notifications />
        
        <div className={`state ${props.room.state}`}>
            <div className={`icon ${props.room.state}`} />
            <p className={`text ${props.room.state}`}>{i18next.t(`room.state.${props.room.state}`)}</p>
        </div>

        <div className='room-link-wrapper'>
            <div className='room-link'>
                <a
                    className='link'
                    href={props.room.url}
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={e => {
                        clipboardCopy(props.room.url).then(onRoomLinkCopy)
                    }}
                >
                    Raumlink
                </a>
            </div>
        </div>

        <Peers />

        <div className={`me-container ${props.amActiveSpeaker ? 'active-speaker' : ''}`}>
            <Me />
        </div>
      </div>
    );
}

function RoomContainer(props: Props) {
    return (
        <RoomContext.Consumer>
            {roomClient => roomClient ? <Room {...props} roomClient={roomClient} /> : <></>}
        </RoomContext.Consumer>
    );
}

export default connector(RoomContainer);
