import * as State from '../store';
import * as Type from '../types';

const initialState: State.Notifications = [];

const notifications = (state: State.Notifications = initialState, konferenzAction: Type.KonferenzAction): State.Notifications => {
    switch (konferenzAction.type) {
        case Type.ADD_NOTIFICATION: {
            const action = konferenzAction as Type.AddNotificationAction;
            return [
                ...state,
                action.notification
            ];
        }

        case Type.REMOVE_NOTIFICATION: {
            const action = konferenzAction as Type.RemoveNotificationAction;
            return state.filter(notification => notification.id !== action.notificationId)
        }

        default:
            return state;
    }
}

export default notifications;
