import React, { useState } from 'react';
import Logger from '../lib/Logger';
import { RootState } from '../redux/store';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { removeNotification } from '../redux/actions';
import classNames from 'classnames';
import '../styles/Notifications.scss';
import { CSSTransition } from 'react-transition-group';
import { Notification as NotificationObject } from '../types';

const log = new Logger('Notifications');
log.debug('Notifications');

const mapState = (state: RootState) => ({
});

const mapDispatch = (dispatch: Dispatch) => ({
    removeNotification: (id: string) => dispatch(removeNotification(id))
});

const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector> & {
    notification: NotificationObject
};

function Notification(props: Props) {
    const notification = props.notification;

    const [notificationShown, setNotificationShown] = useState(true);
    setTimeout(() => {
        setNotificationShown(false);
    }, notification.timeout);

    return (
        <CSSTransition
            in={notificationShown}
            timeout={300}
            classNames="notification"
            unmountOnExit={true}
            onExited={() => props.removeNotification(notification.id)}
            appear
        >
            <div className={classNames('notification', notification.type)} onClick={() => setNotificationShown(false)}>
                <div className="icon" />
                
                <div className="body">
                    {notification.text}
                </div>
            </div>
        </CSSTransition>
    );
}

export default connector(Notification);
