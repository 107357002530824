import randomString from "random-string";
import { Dispatch } from "redux";
import { PeerID } from "../../../commonTypes";
import * as Types from "../types";
import * as State from "./types";

export const setRoomUrl = (url: string): State.SetRoomUrlAction => ({
    type: State.SET_ROOM_URL,
    url
});

export const setRoomState = (state: State.RoomState): State.SetRoomStateAction => ({
    type: State.SET_ROOM_STATE,
    state
});

export const setRoomActiveSpeaker = (peerId: Types.PeerID): State.SetRoomActiveSpeakerAction => ({
    type: State.SET_ROOM_ACTIVE_SPEAKER,
    peerId
});

export const setMe = (me: State.SetMePayload): State.SetMeAction => ({
    type: State.SET_ME,
    me
});

export const setMediaCapabilities = (canSendMic: boolean, canSendWebcam: boolean): State.SetMediaCapabilitiesAction => ({
    type: State.SET_MEDIA_CAPABILITIES,
    canSendMic,
    canSendWebcam
});

export const setCanChangeWebcam = (canChangeWebcam: boolean): State.SetCanChangeWebcamAction => ({
    type: State.SET_CAN_CHANGE_WEBCAM,
    canChangeWebcam
});

export const setDisplayName = (displayName: string): State.SetDisplayNameAction => ({
    type: State.SET_DISPLAY_NAME,
    displayName
});

export const setAudioOnlyState = (audioOnlyState: boolean): State.SetAudioOnlyStateAction => ({
    type: State.SET_AUDIO_ONLY_STATE,
    audioOnly: audioOnlyState
});

export const setAudioOnlyInProgress = (audioOnlyInProgress: boolean): State.SetAudioOnlyInProgressAction => ({
    type: State.SET_AUDIO_ONLY_IN_PROGRESS,
    audioOnlyInProgress
});

export const setAudioMutedState = (audioMuted: boolean): State.SetAudioMutedStateAction => ({
    type: State.SET_AUDIO_MUTED_STATE,
    audioMuted
});

export const setDirectAudio = (peerId: PeerID, directAudio: boolean): State.SetDirectAudioStateAction => ({
    type: State.SET_DIRECT_AUDIO_STATE,
    peerId,
    directAudio
});

export const addProducer = (producer: Types.Producer): State.AddProducerAction => ({
    type: State.ADD_PRODUCER,
    producer
});

export const removeProducer = (producerId: Types.ProducerID): State.RemoveProducerAction => ({
    type: State.REMOVE_PRODUCER,
    producerId
});

export const setProducerPaused = (producerId: Types.ProducerID): State.SetProducerPausedAction => ({
    type: State.SET_PRODUCER_PAUSED,
    producerId
});

export const setProducerResumed = (producerId: Types.ProducerID): State.SetProducerResumedAction => ({
    type: State.SET_PRODUCER_RESUMED,
    producerId
});

export const setProducerTrack = (producerId: Types.ProducerID, track: MediaStreamTrack): State.SetProducerTrackAction => ({
    type: State.SET_PRODUCER_TRACK,
    producerId,
    track
});

export const setProducerScore = (producerId: Types.ProducerID, score: Array<Types.Score>): State.SetProducerScoreAction => ({
    type: State.SET_PRODUCER_SCORE,
    producerId,
    score
});

export const setWebcamInProgress = (webcamInProgress: boolean): State.SetWebcamInProgressAction => ({
    type: State.SET_WEBCAM_IN_PROGRESS,
    webcamInProgress
});

export const setMicInProgress = (micInProgress: boolean): State.SetMicInProgressAction => ({
    type: State.SET_MIC_IN_PROGRESS,
    micInProgress
});

export const addPeer = (peer: Types.Peer): State.AddPeerAction => ({
    type: State.ADD_PEER,
    peer
});

export const removePeer = (peerId: Types.PeerID): State.RemovePeerAction => ({
    type: State.REMOVE_PEER,
    peerId
});

export const setPeerDisplayName = (peerId: Types.PeerID, displayName: string): State.SetPeerDisplayNameAction => ({
    type: State.SET_PEER_DISPLAY_NAME,
    peerId,
    displayName
});

export const setPeerDirectAudioState = (peerId: Types.PeerID, directAudio: boolean): State.SetPeerDirectAudioStateAction => ({
    type: State.SET_PEER_DIRECT_AUDIO_STATE,
    peerId,
    directAudio
});

export const addConsumer = (consumer: Types.Consumer): State.AddConsumerAction => ({
    type: State.ADD_CONSUMER,
    consumer
});

export const removeConsumer = (consumerId: Types.ConsumerID, peerId: Types.PeerID): State.RemoveConsumerAction => ({
    type: State.REMOVE_CONSUMER,
    consumerId,
    peerId
});

export const setConsumerPaused = (consumerId: Types.ConsumerID, originator: Types.Originator): State.SetConsumerPausedAction => ({
    type: State.SET_CONSUMER_PAUSED,
    consumerId,
    originator
});

export const setConsumerResumed = (consumerId: Types.ConsumerID, originator: Types.Originator): State.SetConsumerResumedAction => ({
    type: State.SET_CONSUMER_RESUMED,
    consumerId,
    originator
});

export const setConsumerCurrentLayers = (consumerId: Types.ConsumerID, spatialLayer: number, temporalLayer: number): State.SetConsumerCurrentLayersAction => ({
    type: State.SET_CONSUMER_CURRENT_LAYERS,
    consumerId,
    spatialLayer,
    temporalLayer
});

export const setConsumerPreferredLayers = (consumerId: Types.ConsumerID, spatialLayer: number, temporalLayer: number): State.SetConsumerPreferredLayersAction => ({
    type: State.SET_CONSUMER_PREFERRED_LAYERS,
    consumerId,
    spatialLayer,
    temporalLayer
});

export const setConsumerTrack = (consumerId: Types.ConsumerID, track: MediaStreamTrack): State.SetConsumerTrackAction => ({
    type: State.SET_CONSUMER_TRACK,
    consumerId,
    track
});

export const setConsumerScore = (consumerId: Types.ConsumerID, score: Array<Types.Score>): State.SetConsumerScoreAction => ({
    type: State.SET_CONSUMER_SCORE,
    consumerId,
    score
});

export const addNotification = (notification: Types.Notification): State.AddNotificationAction => ({
    type: State.ADD_NOTIFICATION,
    notification
});

export const removeNotification = (notificationId: string): State.RemoveNotificationAction => ({
    type: State.REMOVE_NOTIFICATION,
    notificationId
});

export const notify = (type: Types.NotificationType, text: string, timeout: number = 0) => {
    if (!timeout) {
        switch (type) {
            case 'info': 
                timeout = 5000;
                break;
            case 'warning':
                timeout = 7500;
                break;
            case 'error':
                timeout = 10000;
        }
    }

    const notification: Types.Notification = {
        id: randomString({length: 8}).toLowerCase(),
        type,
        text,
        timeout
    };

    return (dispatch: Dispatch) => {
        dispatch(addNotification(notification));
    }
};
