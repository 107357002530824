import React from 'react';
import './styles/Konferenz.scss';
import Logger from './lib/Logger';
import { RootState } from './redux/store';
import { connect, ConnectedProps } from 'react-redux';
import Room from './components/Room';
import SetDisplayName from './components/SetDisplayName';

const log = new Logger();
log.debug('Konferenz');

const mapState = (state: RootState) => ({
  me: state.me
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

function Konferenz(props: Props) {
    return (
      <div data-component="Konferenz">
        {props.me.displayNameSet ? <Room/> : <SetDisplayName/>}
      </div>
    );
}

export default connector(Konferenz);
