import React, { useState } from 'react';
import Logger from '../lib/Logger';
import { RootState } from '../redux/store';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import Peer from './Peer';
import classNames from 'classnames';
import '../styles/Peers.scss';
import { CSSTransition } from 'react-transition-group';
import i18next from 'i18next';
import { Peer as RemotePeer } from '../types';

const log = new Logger('Peers');
log.debug('Peers');

const mapState = (state: RootState) => ({
    peers: state.peers,
    activeSpeakerId: state.room.activeSpeaerId
});

const mapDispatch = (dispatch: Dispatch) => ({
});

const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;
function Peers(props: Props) {
    const [activePeer, setActivePeer] = useState<RemotePeer>(null);
    let peerList;
    if (props.peers.size > 0) {
        peerList = Array.from(props.peers.values()).map((peer, index) => (
            <CSSTransition key={peer.id} timeout={250} in appear classNames='fade-in'>
                <div onClick={() => {log.debug(peer); setActivePeer(peer);}} className={classNames('peer-container', {
                    'active-speaker': peer.id === props.activeSpeakerId,
                    'active-peer': activePeer ? (activePeer === peer) : (index === 0)
                })}>
                    <Peer peer={peer} />
                </div>
            </CSSTransition>
        ));
    } else {
        peerList = <CSSTransition timeout={250} in appear classNames='fade-in'>
            <div className='wait-for-peers active-peer peer-container'><span>{i18next.t('room.still-empty')}</span></div>
        </CSSTransition>;
    }
    return (
        <div data-component='Peers'>{
            peerList
        }</div>
    );
}

const ConnectedPeers = connector(Peers);

export default ConnectedPeers;
