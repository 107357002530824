import React from 'react';
import Logger from '../lib/Logger';
import { RootState } from '../redux/store';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { removeNotification } from '../redux/actions';
import Notification from './Notification';
import '../styles/Notifications.scss';

const log = new Logger('Notifications');
log.debug('Notifications');

const mapState = (state: RootState) => ({
    notifications: state.notifications
});

const mapDispatch = (dispatch: Dispatch) => ({
    onClick: (notificationId: string) => dispatch(removeNotification(notificationId))
});

const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

function Notifications(props: Props) {
    return (
        <div data-component="Notifications">
            {
                props.notifications.map(notification => {
                    return (
                        <Notification key={notification.id} notification={notification}/>
                    )
                })
            }
        </div>
    );
}

export default connector(Notifications);
