import { act } from 'react-dom/test-utils';
import * as State from '../store';
import * as Type from '../types';

const initialState: State.Peers = new Map();

const peers = (state: State.Peers = initialState, konferenzAction: Type.KonferenzAction): State.Peers => {
    switch (konferenzAction.type) {
        case Type.SET_ROOM_STATE: {
            const action = konferenzAction as Type.SetRoomStateAction;
            if (action.state === 'closed') {
                return new Map();
            }
            return state;
        }

        case Type.ADD_PEER: {
            const action = konferenzAction as Type.AddPeerAction;
            const newState = new Map(state);
            newState.set(action.peer.id, action.peer);
            return newState;
        }

        case Type.REMOVE_PEER: {
            const action = konferenzAction as Type.RemovePeerAction;
            const newState = new Map(state);
            newState.delete(action.peerId);
            return newState;
        }

        case Type.SET_PEER_DISPLAY_NAME: {
            const action = konferenzAction as Type.SetPeerDisplayNameAction;
            const peer = state.get(action.peerId);
            if (!peer) {
                return state;
            }

            const newPeer: typeof peer = {
                ...peer,
                displayName: action.displayName
            };
            const newState = new Map(state);
            newState.set(peer.id, newPeer);
            return newState;
        }

        case Type.ADD_CONSUMER: {
            const action = konferenzAction as Type.AddConsumerAction;
            const peer = state.get(action.consumer.peerId);
            if (!peer) {
                return state;
            }

            const newPeer: typeof peer = {
                ...peer,
                consumers: [
                    ...peer.consumers,
                    action.consumer.id
                ]
            };
            const newState = new Map(state);
            newState.set(peer.id, newPeer);
            return newState;
        }
    
        case Type.REMOVE_CONSUMER: {
            const action = konferenzAction as Type.RemoveConsumerAction;
            const peer = state.get(action.peerId);
            if (!peer) {
                return state;
            }

            const index = peer.consumers.indexOf(action.consumerId);
            if (index === -1) {
                return state;
            }

            const newConsumers = peer.consumers.slice();
            newConsumers.splice(index, 1);
            const newPeer: typeof peer = {
                ...peer,
                consumers: newConsumers
            };
            const newState = new Map(state);
            newState.set(peer.id, newPeer);
            return newState;
        }

        case Type.SET_DIRECT_AUDIO_STATE: {
            const action = konferenzAction as Type.SetDirectAudioStateAction;
            const peer = state.get(action.peerId);
            if (!peer) {
                return state;
            }
            const newPeer: typeof peer = {
                ...peer,
                directAudio: action.directAudio
            };
            const newState = new Map(state);
            newState.set(peer.id, newPeer);
            return newState;
        }
    
        default:
            return state;
    }
}

export default peers;
