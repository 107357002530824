import i18next from 'i18next';
import React from 'react';
import Logger from '../lib/Logger';
import classNames from 'classnames';
import '../styles/ChooseDevice.scss';

const log = new Logger('ChooseDevice');
log.debug('ChooseDevice');

interface Item {
    id: string;
    label: string;
}

type Props = {
    header: string;
    itemClassPrefix: string;
    items: Item[];
    activeItemId?: string;
    onChoose: (item?: Item) => void;
    onCancel: () => void;
}

export default function ChooseDevice(props: Props) {
    const {
        header,
        itemClassPrefix,
        items,
        activeItemId,
        onChoose,
        onCancel
    } = props;

    const activeClass = `${itemClassPrefix}-active`;
    const disableClass = `${itemClassPrefix}-disable`;

    return (
        <div data-component='ChooseDevice'>
            <div className='cancel' onClick={() => onCancel() }/>
            <div className='header'>{header}</div>
            <ul>
                <li className={classNames(disableClass, {[activeClass]: !activeItemId})} onClick={() => onChoose(null)}>{i18next.t('media.disable')}</li>
                {items.map(item => (
                    <li key={item.id} className={classNames(itemClassPrefix, {[activeClass]: activeItemId === item.id})} onClick={() => onChoose(item)}>{item.label}</li>
                ))}
            </ul>
        </div>
    )
}
