import i18next from 'i18next';
import { createRef, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import RoomClient from '../lib/RoomClient';
import RoomContext from '../lib/RoomContext';
import { RootState } from '../redux/store';
import '../styles/SetDisplayName.scss';
import { Tooltip } from 'react-tooltip';
import classNames from 'classnames';

const mapState = (state: RootState) => ({
    displayName: state.me.displayName
})
const mapDispatch = {
}

const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const SetDisplayName = (props: Props & { roomClient: RoomClient }) => {
    const [enteredDisplayName, updateDisplayName] = useState(props.roomClient.getDisplayName());
    const [useHeadphoneMode, setUseHeadphoneMode] = useState(false);

    const inputElement = createRef<HTMLInputElement>();

    useEffect(() => {
        inputElement.current && inputElement.current.focus();
    });

    const formError = enteredDisplayName === '';

    return (
        <CSSTransition timeout={250} classNames='fade-in' in appear>
            <div data-component="SetDisplayName">
                <form onSubmit={e => {
                    if (!formError) {
                        props.roomClient.setHeadphoneMode(useHeadphoneMode);
                        props.roomClient.changeDisplayName(enteredDisplayName);
                        e.preventDefault();
                    }
                }}>
                    <h1><label htmlFor='name-input'>{i18next.t('lobby.enter-name')}</label></h1>
                    <input ref={inputElement} id='name-input' placeholder={i18next.t('lobby.name-placeholder')} type='text' value={enteredDisplayName} onChange={e => updateDisplayName(e.target.value)} />
                    <label className={classNames('headphone-mode', {'checked': useHeadphoneMode})} data-tip={i18next.t('lobby.headphone-mode-tooltip')}>
                        <input onChange={(e) => setUseHeadphoneMode(!useHeadphoneMode)} checked={useHeadphoneMode} type="checkbox"/>{i18next.t('lobby.headphone-mode')}
                    </label>
                    <button
                        onMouseOver={() => formError && inputElement.current.classList.add('error')}
                        onMouseOut={() => inputElement.current.classList.remove('error')}
                        type="submit"
                        disabled={formError}
                    >
                        {i18next.t('lobby.join-room')}<span />
                    </button>
                </form>
                <Tooltip place='top' effect='solid'/>
            </div>
        </CSSTransition>
    );
};

function SetDisplayNameContainer(props: Props) {
    return (
        <RoomContext.Consumer>
            {roomClient => roomClient ? <SetDisplayName {...props} roomClient={roomClient} /> : <></>}
        </RoomContext.Consumer>
    );
}

export default connector(SetDisplayNameContainer);

