import Logger from './Logger';

const OPUS_OK = 0;
const OPUS_ERR = -1;
type OpusStatus = typeof OPUS_OK | typeof OPUS_ERR;

interface EncoderMessage {
    status: OpusStatus;
    reason?: any;
    packets?: Array<{
        data: ArrayBuffer
    }>;
};

interface DecoderMesssage {
    status: OpusStatus;
    ret?: number;
    reason?: any;
    sampling_rate?: number;
    num_of_channels?: number;
    samples?: Float32Array;
};

export class OpusEncoder {
    protected log: Logger;

    protected worker: Worker;

    protected opusHeader: ArrayBuffer|null;

    protected opusHeaderResolvers: Array<(header: ArrayBuffer) => void> = [];

    protected onPacket: (packet: ArrayBuffer) => void;

    protected onError: (error: string) => void;

    constructor(
        {
            onPacket,
            onError
        } : {
            onPacket: (packet: ArrayBuffer) => void,
            onError: (error: string) => void
        } 
    ) {
        this.log = new Logger('OpusEncoder');
        this.worker = new Worker(process.env.PUBLIC_URL + '/libopus/opus_encoder.js', { type: 'module' });
        this.opusHeader = null;
        this.onPacket = onPacket;
        this.onError = onError;

        this.log.debug('OpusEncoder');

        this.worker.onmessage = (e: MessageEvent<EncoderMessage>) => {
            if (e.data.status === OPUS_OK) {
                this.opusHeader = e.data.packets![0].data;
                this.worker.onmessage = this.onWorkerMessage.bind(this);
                this.opusHeaderResolvers.forEach(resolve => resolve(this.opusHeader!));
                this.opusHeaderResolvers.length = 0;
            } else {
                this.log.error('Setting up opus encoder failed: %o', e.data)
                this.onError(e.data.reason);
            }
        }

        this.worker.postMessage({
            num_of_channels: 2,
            sampling_rate: 48000,
            params: {
                // application: 2051, // Restricted low latency
                frame_duration: 10
            }
        });
    }

    encode(samples: Float32Array) {
        this.worker.postMessage({
            samples
        });
    }

    async getOpusHeader(): Promise<ArrayBuffer> {
        if (this.opusHeader) {
            return this.opusHeader;
        } else {
            return new Promise((resolve, reject) => {
                this.opusHeaderResolvers.push(resolve);
            });
        }
    }

    protected onWorkerMessage(e: MessageEvent<EncoderMessage>) {
        if (e.data.status === OPUS_OK) {
            for (const packet of e.data.packets!) {
                this.onPacket(packet.data);
            }
        } else {
            this.log.error('Opus encoder failed: %o', e.data.reason);
        }
    }
};

export class OpusDecoder {
    protected log: Logger;

    protected worker: Worker;

    protected sampeRate: number = 0;

    protected channelCount: number = 0;

    protected onAudio: (audio: Float32Array) => void;

    protected onError: (error: string) => void;

    constructor(onAudio: (audio: Float32Array) => void, onError: (error: string) => void) {
        this.log = new Logger('OpusDecoder');
        this.worker = new Worker(process.env.PUBLIC_URL + '/libopus/opus_decoder.js', { type: 'module' });
        this.onAudio = onAudio;
        this.onError = onError;

        this.log.debug('OpusDecoder');

        this.worker.onmessage = (e: MessageEvent<DecoderMesssage>) => {
            if (e.data.status === OPUS_OK) {
                this.sampeRate = e.data.sampling_rate!;
                this.channelCount = e.data.num_of_channels!;
                this.worker.onmessage = this.onWorkerMessage.bind(this);
            } else {
                this.log.error('Setting up opus decoder failed: %o', e.data);
                this.onError(e.data.reason);
            }
        };
    }

    protected onWorkerMessage(e: MessageEvent<DecoderMesssage>) {
        if (e.data.status === OPUS_OK) {
            this.onAudio(e.data.samples!);
        } else {
            this.log.error('Decoding failed: %o', new Uint8Array((e.data as any).packet));
            this.onError(e.data.reason);
        }
    }

    decode(data: ArrayBuffer) {
        this.worker.postMessage({
            packet: data
        });
    }

    getSampleRate(): number {
        return this.sampeRate;
    }

    getChannelCount(): number {
        return this.channelCount;
    }
};
