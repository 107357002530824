import * as State from '../store';
import * as Type from '../types';

const initialState: State.Room = {
    url: '',
    state: 'new',
    activeSpeaerId: null
}

const room = (state: State.Room = initialState, konferenzAction: Type.KonferenzAction): State.Room => {
    switch (konferenzAction.type) {
        case Type.SET_ROOM_URL: {
            const action = konferenzAction as Type.SetRoomUrlAction;
            return {
                ...state,
                url: action.url
            };
        }

        case Type.SET_ROOM_STATE: {
            const action = konferenzAction as Type.SetRoomStateAction;
            if (action.state === 'connected') {
                return {
                    ...state,
                    state: action.state
                };
            } else {
                return {
                    ...state,
                    state: action.state,
                    activeSpeaerId: null
                };
            }
        }

        case Type.SET_ROOM_ACTIVE_SPEAKER: {
            const action = konferenzAction as Type.SetRoomActiveSpeakerAction;
            return {
                ...state,
                activeSpeaerId: action.peerId
            };
        }

        case Type.REMOVE_PEER: {
            const action = konferenzAction as Type.RemovePeerAction;

            if (action.peerId === state.activeSpeaerId) {
                return {
                    ...state,
                    activeSpeaerId: null
                }
            }

            return state;
        }

        default:
            return state;
    }
}

export default room;
