import { OpusDecoder, OpusEncoder } from "./Opus";
import JsPeer, { DataConnection } from 'peerjs';
import Logger from "./Logger";
import i18next from "i18next";
import { KonferenzAction, NotificationAction } from "../redux/types";
import { notify, setDirectAudio } from "../redux/actions";
import { PeerID } from "../types";

const log = new Logger('DirectAudio');

declare global {
    interface Window {
        webkitAudioContext: typeof AudioContext
    }
    interface BaseAudioContext {
        createJavaScriptNode(bufferSize?: number, numberOfInputChannels?: number, numberOfOutputChannels?: number): ScriptProcessorNode;
    }
}

const isWebKit = window.webkitAudioContext !== undefined;
const audioContextSupported = isWebKit || window.AudioContext !== undefined;

export default class DirectAudio {
    protected dispatch: (action: KonferenzAction | NotificationAction) => void;

    protected peerId: PeerID;

    protected audioContext: AudioContext;

    protected opusEncoder: OpusEncoder;

    protected jsPeer: JsPeer;

    protected connections: Map<PeerID, DataConnection> = new Map();

    protected audioSource?: MediaStreamAudioSourceNode;

    protected audioProcessor?: ScriptProcessorNode;

    constructor(myPeerId: PeerID, dispatch: (action: KonferenzAction | NotificationAction) => void) {
        if (!audioContextSupported) {
            throw new Error('AudioContext not supported on this browser');
        }
        this.peerId = myPeerId;
        this.dispatch = dispatch;
        this.audioContext = new (isWebKit ? window.webkitAudioContext : window.AudioContext)({
            latencyHint: 'interactive',
            sampleRate: 48000
        });
        this.opusEncoder = new OpusEncoder({
            onPacket: packet => {
                Array.from(this.connections.values()).map(connection => connection.open && connection.send(packet));
            },
            onError: error => {
                log.error('Opus encoder error: %s', error);
                this.dispatch(notify('error', i18next.t('error.media.audio-codec-error', { error })));
            }
        });

        this.jsPeer = new JsPeer(myPeerId);
        this.jsPeer.on('connection', this.onConnection.bind(this));
    }

    protected onConnection(c: DataConnection) {
        log.debug('onConnection()');

        let remotePeerId: PeerID | null = null;
        const pastRtt: Array<number> = [];
        let jitterBuffer: Array<number> = [];
        let smoothJitter: number = 0;

        const scriptProcessor = (this.audioContext.createScriptProcessor || this.audioContext.createJavaScriptNode).bind(this.audioContext)(256, 0, 1);
        scriptProcessor.onaudioprocess = e => {
            e.outputBuffer.copyToChannel(new Float32Array(jitterBuffer.slice(0, 256)), 0);
            jitterBuffer = jitterBuffer.slice(256);
        };
        scriptProcessor.connect(this.audioContext.destination);

        const opusDecoder = new OpusDecoder(samples => {
            let squareSum = 0;
            samples.map(sample => jitterBuffer.push(sample));
            let jitterBufferTargetSize = 1024 + 48 * smoothJitter;
        }, error => {
            c.close();
        });

        const pingInterval = setInterval(() => {
            c.send(Date.now());
        }, 100);

        let isFirst = false;

        c.on('data', data => {
            if (typeof data === 'number') {
                const rtt = Date.now() - data;
                pastRtt.push(rtt);
                if (pastRtt.length > 1) {
                    let jitter = 0;
                    for (let i = 0; i < pastRtt.length - 1; i++) {
                        jitter += Math.abs(pastRtt[i] - pastRtt[i + 1]);
                    }
                    jitter /= pastRtt.length - 1;
                    smoothJitter = 0.99 * smoothJitter + 0.01 * jitter;
                    // log.debug('Jitter: %f', smoothJitter);
                    if (pastRtt.length === 100) {
                        pastRtt.shift();
                    }
                }
                return;
            }
            if (remotePeerId) {
                // opusDecoder.decode(data);
            } else {
                remotePeerId = data as PeerID;
                this.dispatch(setDirectAudio(remotePeerId, true));
            }
        });

        c.on('close', () => {
            if (remotePeerId) {
                this.dispatch(setDirectAudio(remotePeerId, false));
            }
            clearInterval(pingInterval);
        })
    }

    startEncoding(track: MediaStreamTrack) {
        log.debug('startEncoding()');
        if (this.audioSource) {
            this.stopEncoding();
        } 
        const stream = new MediaStream([track]);
        this.audioSource = this.audioContext.createMediaStreamSource(stream);
        console.log(typeof this.audioContext);
        this.audioProcessor = (this.audioContext.createScriptProcessor || this.audioContext.createJavaScriptNode).bind(this.audioContext)(256, 1, 0);
        this.audioProcessor.onaudioprocess = e => {
            this.opusEncoder.encode(e.inputBuffer.getChannelData(0));
        }
        this.audioSource.connect(this.audioProcessor);
    }

    stopEncoding() {
        log.debug('stopEncoding()');
        if (!this.audioSource) {
            return;
        }
        this.audioSource.disconnect();
        this.audioSource = undefined;
        this.audioProcessor = undefined;
    }

    addPeer(peerId: string) {
        return; // Disable for now.

        log.debug('addPeer(%s)', peerId);
        if (this.connections.has(peerId)) {
            log.warn('addPeer(%s): Peer already added.', peerId);
            return;
        }

        const connection = this.jsPeer.connect(peerId);

        connection.on('open', async () => {
            log.debug('dataConnection(%s)$open', peerId);
            connection.send(this.peerId);
            connection.send(await this.opusEncoder.getOpusHeader());
        });

        connection.on('error', e => {
            log.error('dataConnection(%s)$error: %o', peerId, e);
        });

        connection.on('close', () => {
            log.warn('dataConnection(%s)$close', peerId);
        });

        // Will send pings back.
        connection.on('data', data => connection.send(data));

        this.connections.set(peerId, connection);
    }

    removePeer(peerId: string) {
        log.debug('removePeer(%s)', peerId);
        const connection = this.connections.get(peerId)!;
        if (!connection) {
            log.warn('removePeer(%s): Peer not present.', peerId);
            return;
        }

        connection.close();
        this.connections.delete(peerId);
    }
};