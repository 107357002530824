import debug from 'debug';

const APP_NAME = 'Konferenz';

export default class Logger
{
    readonly debug: debug.Debugger;
    readonly warn: debug.Debugger;
    readonly error: debug.Debugger;

    constructor(prefix: string = '')
    {
        if (prefix.length > 0) {
            this.debug = debug(`${APP_NAME}:${prefix}:`);
            this.warn = debug(`${APP_NAME}:Warn:${prefix}:`);
            this.error = debug(`${APP_NAME}:Error:${prefix}:`);
        } else {
            this.debug = debug(`${APP_NAME}:`);
            this.warn = debug(`${APP_NAME}:Warn:`);
            this.error = debug(`${APP_NAME}:Error:`);
        }

        /* eslint-disable no-console */
        this.debug.log = console.info.bind(console);
        this.warn.log = console.warn.bind(console);
        this.error.log = console.error.bind(console);
        /* eslint-enable no-console */
    }
};
