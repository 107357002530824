import React, { useEffect, useState } from 'react';
import Logger from '../lib/Logger';
import { RootState } from '../redux/store';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import RoomClient from '../lib/RoomClient';
import RoomContext from '../lib/RoomContext';
import PeerView from './PeerView';
import classNames from 'classnames';
import { notify, setWebcamInProgress, setMicInProgress } from '../redux/actions';
import { NotificationType } from '../types';
import { ThunkDispatch } from 'redux-thunk';
import '../styles/Me.scss';
import { CSSTransition } from 'react-transition-group';
import ChooseDevice from './ChooseDevice';
import i18next from 'i18next';

const log = new Logger('Me');
log.debug('Me');

type MeProps = {
};

const mapState = (state: RootState, props: MeProps) => {
    const producerArray = Array.from(state.producers.values());

    const audioProducer = producerArray.find(
        producer => producer?.track.kind === 'audio'
    );
    const videoProducer = producerArray.find(
        producer => producer?.track.kind === 'video'
    );

    return {
        connected: state.room.state === 'connected',
        me: state.me,
        audioProducer,
        videoProducer
    };
};

const mapDispatch = (dispatch: Dispatch) => ({
    notify: (type: NotificationType, text: string, title?: string) => (dispatch as ThunkDispatch<RootState, void, AnyAction>)(notify(type, text)),
    setWebcamInProgress: (flag: boolean) => dispatch(setWebcamInProgress(flag)),
    setMicInProgress: (flag: boolean) => dispatch(setMicInProgress(flag))
});

const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector> & MeProps;

type DeviceState = 'unsupported' | 'off' | 'on';

function Me(props: Props & { roomClient: RoomClient }) {
    const {
        roomClient,
        connected,
        me,
        audioProducer,
        videoProducer,
        setWebcamInProgress,
        setMicInProgress
    } = props;

    let micState: DeviceState;
    if (!me.canSendMic) {
        micState = 'unsupported';
    } else if (!audioProducer) {
        micState = 'unsupported';
    } else if (audioProducer.paused) {
        micState = 'off';
    } else {
        micState = 'on';
    }

    let webcamState: DeviceState;
    if (!me.canSendWebcam) {
        webcamState = 'unsupported';
    } else if (videoProducer) {
        webcamState = 'on';
    } else {
        webcamState = 'off';
    }

    // let canChangeWebcam: DeviceState;
    // if (videoProducer && me.canChangeWebcam) {
    //     canChangeWebcam = 'on';
    // } else {
    //     canChangeWebcam = 'unsupported';
    // }

    const videoVisible = Boolean(videoProducer) && !videoProducer!.paused;

    const [ chooseWebcam, setChooseWebcam ] = useState<MediaDeviceInfo[]>([]);
    const [ chooseMic, setChooseMic ] = useState<MediaDeviceInfo[]>([]);
    const [ webcamMirrored, setWebcamMirrored ] = useState<boolean>(true);

    return (
        <CSSTransition timeout={250} in appear classNames='fade-in'>
            <div data-component="Me">
                {connected && (
                    <div className={classNames('controls', {'has-overlay': chooseMic.length > 0 || chooseWebcam.length > 0})}>
                        <div
                            className={classNames('button', 'mic', micState, { disabled: me.micInProgress, 'in-progress': me.micInProgress })}
                            onClick={() => {
                                micState === 'on' ? roomClient.muteMic() : roomClient.unmuteMic()
                            }}
                            onContextMenu={e => {
                                setMicInProgress(true);
                                roomClient.getMics().then(mics => setChooseMic(mics));
                                e.preventDefault();
                            }}
                        />
                        <div
                            className={classNames('button', 'webcam', webcamState, { disabled: me.webcamInProgress, 'in-progress': me.webcamInProgress })}
                            onClick={() => webcamState === 'on' ? roomClient.disableWebcam() : roomClient.enableWebcam()}
                            onContextMenu={e => {
                                setWebcamInProgress(true);
                                roomClient.getWebcams().then(webcams => setChooseWebcam(webcams));
                                e.preventDefault();
                            }}
                        />
                        {webcamState == 'on' && (
                        <div
                            className={classNames('button', 'webcam-mirror', { 'on': webcamMirrored })}
                            onClick={() => setWebcamMirrored(!webcamMirrored)}
                        />)}
                        {/* <div
                            className={classNames('button', 'change-webcam', canChangeWebcam, { disabled: me.webcamInProgress })}
                            onClick={() => roomClient.changeWebcam()}
                        /> */}
                    </div>
                )}
                
                {chooseWebcam.length > 0 && (
                    <div className={classNames('choose', 'webcam')}>
                        <ChooseDevice
                            header={i18next.t('media.choose-webcam')}
                            itemClassPrefix='webcam'
                            items={chooseWebcam.map(webcam => ({id: webcam.deviceId, label: webcam.label}))}
                            activeItemId={webcamState === 'on' ? roomClient.getActiveWebcamId() : null}
                            onChoose={item => {
                                if (item) {
                                    roomClient.disableWebcam()
                                        .then(() => roomClient.enableWebcam(item.id));
                                } else {
                                    roomClient.disableWebcam()
                                    setWebcamInProgress(false);
                                }
                                setChooseWebcam([]);
                            }}
                            onCancel={() => {
                                setWebcamInProgress(false);
                                setChooseWebcam([]);
                            }}
                        />
                    </div>
                )}

                {chooseMic.length > 0 && (
                    <div className={classNames('choose', 'mic')}>
                        <ChooseDevice
                            header={i18next.t('media.choose-mic')}
                            itemClassPrefix='mic'
                            items={chooseMic.map(mic => ({id: mic.deviceId, label: mic.label}))}
                            activeItemId={micState === 'on' ? roomClient.getActiveMicId() : null}
                            onChoose={item => {
                                if (item) {
                                    roomClient.disableMic()
                                        .then(() => roomClient.enableMic(item.id));
                                } else {
                                    roomClient.muteMic()
                                    setMicInProgress(false);
                                }
                                setChooseMic([]);
                            }}
                            onCancel={() => {
                                setMicInProgress(false);
                                setChooseMic([]);
                            }}
                        />
                    </div>
                )}

                <div className={classNames('peer-view', {'has-overlay': chooseMic.length > 0 || chooseWebcam.length > 0})}>
                    <PeerView
                        isMe
                        videoMirrored={webcamMirrored}
                        peer={me}
                        audioTrack={audioProducer?.track}
                        videoTrack={videoProducer?.track}
                        videoVisible={videoVisible}
                        videoLayer={{}}
                    />
                </div>
            </div>
        </CSSTransition>
    )
}

function MeContainer(props: Props) {
    return (
        <RoomContext.Consumer>
            {roomClient => roomClient ? <Me {...props} roomClient={roomClient} /> : <></>}
        </RoomContext.Consumer>
    );
}

export default connector(MeContainer);
