import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import translation_de from "./translations/de.json";

const i18nextInit = () => {
    i18next
        .use(initReactI18next)
        .init({
            interpolation: { escapeValue: false },  // React already does escaping
            lng: 'de',
            resources: {
                de: {
                    translation: translation_de
                }
            }
        });
};

export default i18nextInit;
