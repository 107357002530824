import * as State from '../store';
import * as Type from '../types';

const initialState: State.Producers = new Map();

const producers = (state: State.Producers = initialState, konferenzAction: Type.KonferenzAction): State.Producers => {
    switch (konferenzAction.type) {
        case Type.SET_ROOM_STATE: {
            const action = konferenzAction as Type.SetRoomStateAction;
            if (action.state === 'closed') {
                return new Map();
            } else {
                return state;
            }
        }

        case Type.ADD_PRODUCER: {
            const action = konferenzAction as Type.AddProducerAction;
            const producer = action.producer;
            const newState = new Map(state);
            newState.set(producer.id, producer);
            return newState;
        }

        case Type.REMOVE_PRODUCER: {
            const action = konferenzAction as Type.RemoveProducerAction;
            const newState = new Map(state);
            newState.delete(action.producerId);
            return newState;
        }

        case Type.SET_PRODUCER_PAUSED: {
            const action = konferenzAction as Type.SetProducerPausedAction;
            const producer = state.get(action.producerId);
            if (!producer) {
                return state;
            }

            const newState = new Map(state);
            const newProducer: typeof producer = {
                ...producer,
                paused: true
            };
            newState.set(producer.id, newProducer);
            return newState;
        }

        case Type.SET_PRODUCER_RESUMED: {
            const action = konferenzAction as Type.SetProducerResumedAction;
            const producer = state.get(action.producerId);
            if (!producer) {
                return state;
            }

            const newState = new Map(state);
            const newProducer: typeof producer = {
                ...producer,
                paused: false
            };
            newState.set(producer.id, newProducer);

            return newState;
        }

        case Type.SET_PRODUCER_TRACK: {
            const action = konferenzAction as Type.SetProducerTrackAction;
            const producer = state.get(action.producerId);
            if (!producer) {
                return state;
            }
            
            const newState = new Map(state);
            const newProducer: typeof producer = {
                ...producer,
                track: action.track
            };
            newState.set(producer.id, newProducer);
            return newState;
        }

        case Type.SET_PRODUCER_SCORE: {
            const action = konferenzAction as Type.SetProducerScoreAction;
            const producer = state.get(action.producerId);
            if (!producer) {
                return state;
            }
            
            const newState = new Map(state);
            const newProducer: typeof producer = {
                ...producer,
                score: action.score
            };
            newState.set(producer.id, newProducer);
            return newState;
        }
            
        default:
            return state;
    }
}

export default producers;
