import { RtpParameters } from "mediasoup-client/lib/types";
import { Action, AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { Codec, Consumer, ConsumerID, ConsumerType, Notification, NotificationType, Originator, Peer, PeerID, Producer, ProducerID, Score } from "../types";
import { RootState } from "./store";

export const SET_ROOM_URL = 'SET_ROOM_URL';
export const SET_ROOM_ACTIVE_SPEAKER = 'SET_ROOM_ACTIVE_SPEAKER';
export const SET_ME = 'SET_ME';
export const SET_MEDIA_CAPABILITIES = 'SET_MEDIA_CAPABILITIES';
export const SET_CAN_CHANGE_WEBCAM = 'SET_CAN_CHANGE_WEBCAM';
export const SET_AUDIO_ONLY_STATE = 'SET_AUDIO_ONLY_STATE';
export const SET_AUDIO_ONLY_IN_PROGRESS = 'SET_AUDIO_ONLY_IN_PROGRESS';
export const SET_AUDIO_MUTED_STATE = 'SET_AUDIO_ONLY_MUTED_STATE';
export const SET_DIRECT_AUDIO_STATE = 'SET_DIRECT_AUDIO_STATE';
export const ADD_PRODUCER = 'ADD_PRODUCER';
export const REMOVE_PRODUCER = 'REMOVE_PRODUCER';
export const SET_PRODUCER_PAUSED = 'SET_PRODUCER_PAUSED';
export const SET_PRODUCER_RESUMED = 'SET_PRODUCER_RESUMED';
export const SET_PRODUCER_TRACK = 'SET_PRODUCER_TRACK';
export const SET_PRODUCER_SCORE = 'SET_PRODUCER_SCORE';
export const SET_WEBCAM_IN_PROGRESS = 'SET_WEBCAM_IN_PROGRESS';
export const SET_MIC_IN_PROGRESS = 'SET_MIC_IN_PROGRESS';
export const ADD_PEER = 'ADD_PEER';
export const REMOVE_PEER = 'REMOVE_PEER';
export const SET_PEER_DISPLAY_NAME = 'SET_PEER_DISPLAY_NAME';
export const SET_PEER_DIRECT_AUDIO_STATE = 'SET_PEER_DIRECT_AUDIO_STATE';
export const ADD_CONSUMER = 'ADD_CONSUMER';
export const REMOVE_CONSUMER = 'REMOVE_CONSUMER';
export const SET_CONSUMER_PAUSED = 'SET_CONSUMER_PAUSED';
export const SET_CONSUMER_RESUMED = 'SET_CONSUMER_RESUMED';
export const SET_CONSUMER_CURRENT_LAYERS = 'SET_CONSUMER_CURRENT_LAYERS';
export const SET_CONSUMER_PREFERRED_LAYERS = 'SET_CONSUMER_PREFERRED_LAYERS';
export const SET_CONSUMER_TRACK = 'SET_CONSUMER_TRACK';
export const SET_CONSUMER_SCORE = 'SET_CONSUMER_SCORE';
export const SET_ROOM_STATE = 'SET_ROOM_STATE';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const SET_DISPLAY_NAME = 'SET_DISPLAY_NAME';
export type RoomState = 'new'|'connecting'|'awaiting-media'|'connected'|'disconnected'|'closed';

export type NotificationAction = ThunkAction<void, RootState, unknown, Action<string>>;

export interface KonferenzAction extends AnyAction {}

export interface SetRoomUrlAction extends KonferenzAction {
    type: typeof SET_ROOM_URL,
    url: string
};

export interface SetRoomActiveSpeakerAction extends KonferenzAction {
    type: typeof SET_ROOM_ACTIVE_SPEAKER,
    peerId: PeerID
};

export interface SetMePayload {
    id: PeerID,
    displayName: string
}

export interface SetMeAction extends KonferenzAction {
    type: typeof SET_ME,
    me: SetMePayload
};

export interface SetMediaCapabilitiesAction extends KonferenzAction {
    type: typeof SET_MEDIA_CAPABILITIES,
    canSendMic: boolean,
    canSendWebcam: boolean
};

export interface SetCanChangeWebcamAction extends KonferenzAction {
    type: typeof SET_CAN_CHANGE_WEBCAM,
    canChangeWebcam: boolean
};

export interface SetDisplayNameAction extends KonferenzAction {
    type: typeof SET_DISPLAY_NAME;
    displayName: string; 
}

export interface SetAudioOnlyStateAction extends KonferenzAction {
    type: typeof SET_AUDIO_ONLY_STATE,
    audioOnly: boolean
};

export interface SetAudioOnlyInProgressAction extends KonferenzAction {
    type: typeof SET_AUDIO_ONLY_IN_PROGRESS,
    audioOnlyInProgress: boolean
};

export interface SetAudioMutedStateAction extends KonferenzAction {
    type: typeof SET_AUDIO_MUTED_STATE;
    audioMuted: boolean
}

export interface SetDirectAudioStateAction extends KonferenzAction {
    type: typeof SET_DIRECT_AUDIO_STATE;
    peerId: string;
    directAudio: boolean;
}

export interface AddProducerAction extends KonferenzAction {
    type: typeof ADD_PRODUCER,
    producer: Producer
};

export interface RemoveProducerAction extends KonferenzAction {
    type: typeof REMOVE_PRODUCER,
    producerId: ProducerID
};

export interface SetProducerPausedAction extends KonferenzAction {
    type: typeof SET_PRODUCER_PAUSED,
    producerId: ProducerID
};

export interface SetProducerResumedAction extends KonferenzAction {
    type: typeof SET_PRODUCER_RESUMED,
    producerId: ProducerID
};

export interface SetProducerTrackAction extends KonferenzAction {
    type: typeof SET_PRODUCER_TRACK,
    producerId: ProducerID,
    track: MediaStreamTrack
};

export interface SetProducerScoreAction extends KonferenzAction {
    type: typeof SET_PRODUCER_SCORE,
    producerId: ProducerID,
    score: Array<Score>
};

export interface SetWebcamInProgressAction extends KonferenzAction {
    type: typeof SET_WEBCAM_IN_PROGRESS,
    webcamInProgress: boolean
};

export interface SetMicInProgressAction extends KonferenzAction {
    type: typeof SET_MIC_IN_PROGRESS,
    micInProgress: boolean
};

export interface AddPeerAction extends KonferenzAction {
    type: typeof ADD_PEER,
    peer: Peer
}

export interface RemovePeerAction extends KonferenzAction {
    type: typeof REMOVE_PEER,
    peerId: PeerID
};

export interface SetPeerDisplayNameAction extends KonferenzAction {
    type: typeof SET_PEER_DISPLAY_NAME,
    peerId: PeerID,
    displayName: string
};

export interface SetPeerDirectAudioStateAction extends KonferenzAction {
    type: typeof SET_PEER_DIRECT_AUDIO_STATE,
    peerId: PeerID,
    directAudio: boolean
};

export interface AddConsumerAction extends KonferenzAction {
    type: typeof ADD_CONSUMER,
    consumer: Consumer
}

export interface RemoveConsumerAction extends KonferenzAction {
    type: typeof REMOVE_CONSUMER,
    consumerId: ConsumerID,
    peerId: PeerID
};

export interface SetConsumerPausedAction extends KonferenzAction {
    type: typeof SET_CONSUMER_PAUSED,
    consumerId: ConsumerID,
    originator: Originator
};

export interface SetConsumerResumedAction extends KonferenzAction {
    type: typeof SET_CONSUMER_RESUMED,
    consumerId: ConsumerID,
    originator: Originator
};

export interface SetConsumerCurrentLayersAction extends KonferenzAction {
    type: typeof SET_CONSUMER_CURRENT_LAYERS,
    consumerId: ConsumerID,
    spatialLayer: number,
    temporalLayer: number
};

export interface SetConsumerPreferredLayersAction extends KonferenzAction {
    type: typeof SET_CONSUMER_PREFERRED_LAYERS,
    consumerId: ConsumerID,
    spatialLayer: number,
    temporalLayer: number
};

export interface SetConsumerTrackAction extends KonferenzAction {
    type: typeof SET_CONSUMER_TRACK,
    consumerId: ConsumerID,
    track: MediaStreamTrack
};

export interface SetConsumerScoreAction extends KonferenzAction {
    type: typeof SET_CONSUMER_SCORE,
    consumerId: ConsumerID,
    score: Array<Score>
};

export interface SetRoomStateAction extends KonferenzAction {
    type: typeof SET_ROOM_STATE;
    state: RoomState
}

export interface SetRoomUrlAction extends KonferenzAction {
    type: typeof SET_ROOM_URL;
    url: string;
}

export interface AddNotificationAction extends KonferenzAction {
    type: typeof ADD_NOTIFICATION;
    notification: Notification;
};

export interface RemoveNotificationAction extends KonferenzAction {
    type: typeof REMOVE_NOTIFICATION;
    notificationId: string;
};

