import React, {useState} from 'react';
import Logger from '../lib/Logger';
import { RootState } from '../redux/store';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import RoomClient from '../lib/RoomClient';
import RoomContext from '../lib/RoomContext';
import classNames from 'classnames'
import { Peer as PeerType } from '../types';
import PeerView from './PeerView';
import '../styles/Peer.scss';
import { Tooltip } from 'react-tooltip';
import i18next from 'i18next';

const log = new Logger('Peer');
log.debug('Peer');

type PeerProps = {
    peer: PeerType
}

const mapState = (state: RootState, props: PeerProps) => {
    const consumerArray = props.peer.consumers!.map(
        consumerId => state.consumers.get(consumerId)
    );
    const audioConsumer = consumerArray.find(
        consumer => consumer?.track.kind === 'audio'
    );
    const videoConsumer = consumerArray.find(
        consumer => consumer?.track.kind === 'video'
    );

    return {
        audioConsumer,
        videoConsumer,
        videoLayer: {
            spatialLayer: videoConsumer?.currentSpatialLayer,
            temporalLayer: videoConsumer?.currentTemporalLayer
        },
        audioMuted: state.me.audioMuted,
        directAudio: props.peer.directAudio
    };
};

const mapDispatch = (dispatch: Dispatch) => ({
});

const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector> & PeerProps;

function Peer(props: Props & { roomClient: RoomClient }) {
    const {
        peer,
        audioConsumer,
        videoConsumer,
        audioMuted,
        directAudio,
        videoLayer
    } = props;

    const audioEnabled = (
        Boolean(audioConsumer) &&
        !audioConsumer?.locallyPaused &&
        !audioConsumer?.remotelyPaused
    );

    const videoVisible = (
        Boolean(videoConsumer) &&
        !videoConsumer?.locallyPaused &&
        !videoConsumer?.remotelyPaused
    );

    const [videoEnabled, setVideoEnabled] = useState<boolean>(true)

    return (
        <div data-component="Peer">
            <div className='indicators'>
                {!audioEnabled ? <div className='icon mic-off' data-tip={i18next.t('tooltip.mic-off')}/> : <></>}
                {!videoVisible ? <div className='icon webcam-off' data-tip={i18next.t('tooltip.webcam-off')}/> : <></>}
                {videoLayer.spatialLayer !== undefined &&
                    <div className={`icon quality-${videoLayer.spatialLayer}-${videoLayer.temporalLayer}`} data-tip={i18next.t('tooltip.quality')}/>
                }
                {directAudio && 
                    <div className='icon mic-p2p' data-tip={i18next.t('tooltip.mic-p2p')}/>
                }
                {videoVisible && (
                    <div className={classNames('icon', 'control', videoEnabled ? 'webcam-disable' : 'webcam-enable')} onClick={(e) => {
                        setVideoEnabled(!videoEnabled);
                        e.stopPropagation();
                    }}/>
                )}
            </div>

            <PeerView
                peer={peer}
                audioTrack={audioConsumer?.track}
                videoTrack={videoConsumer?.track}
                audioMuted={audioMuted}
                directAudio={directAudio}
                videoVisible={videoVisible && videoEnabled}
                videoLayer={props.videoLayer}
            />

            <Tooltip place='bottom' effect='solid'/>
        </div>
    )
}

function PeerContainer(props: Props) {
    return (
        <RoomContext.Consumer>
            {roomClient => roomClient ? <Peer {...props} roomClient={roomClient} /> : <></>}
        </RoomContext.Consumer>
    );
}

export default connector(PeerContainer);
