import protooClient from 'protoo-client';
import * as mediasoupClient from 'mediasoup-client';
import Logger from './Logger';
import * as Actions from '../redux/actions';
import * as Types from '../types';
import i18next from 'i18next';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, Store as ReduxStore } from 'redux';
import { RootState } from '../redux/store';
import { RtpCodecCapability } from 'mediasoup-client/lib/types';
import { KonferenzAction, NotificationAction } from '../redux/types';
import DirectAudio from './DirectAudio';

const log = new Logger('RoomClient');
log.debug('Starting in %s context.', process.env.NODE_ENV)
let ServerUrl: string;
if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
    ServerUrl = 'wss://conference.0x2a.link:40443/';
    // ServerUrl = 'wss://10.1.0.228:40443/';
} else {
    ServerUrl = 'wss://conference.0x2a.link/ws';
}

const VIDEO_CONSTRAINTS = {
    qxga: { width: 800, height: 480, frameRate: 30 },
    hd: { width: 1280, height: 720, frameRate: 30 },
    fhd: { width: 1920, height: 1080, frameRate: 30 }
};

const MIC_ID_STORE = 'mic-id';
const WEBCAM_ID_STORE = 'webcam-id';

type WebCamType = 'front' | 'back';

const supportedConstraints = navigator.mediaDevices.getSupportedConstraints() as any;

interface NewMediaTrackConstraints extends MediaTrackConstraints {
    noiseSuppression?: ConstrainBoolean;
    autoGainControl?: ConstrainBoolean;
    latency?: ConstrainDouble;
}

let recordingConstraints: {
    headphone: NewMediaTrackConstraints;
    speaker: NewMediaTrackConstraints;
} = {
    headphone: {},
    speaker: {}
};

if (supportedConstraints.echoCancellation) {
    recordingConstraints.headphone.echoCancellation = { exact: false };
}
if (supportedConstraints.noiseSuppression) {
    recordingConstraints.headphone.noiseSuppression = { exact: false };
}
if (supportedConstraints.autoGainControl) {
    recordingConstraints.headphone.autoGainControl = { exact: false };
    recordingConstraints.speaker.autoGainControl = { exact: false };
}

if (supportedConstraints.latency) {
    recordingConstraints.headphone.latency = 0.01;
    recordingConstraints.speaker.latency = 0.01;
}

recordingConstraints.headphone.channelCount = 2;
recordingConstraints.headphone.sampleRate = 48000;

type AppData = {
    peerId: string;
}

export default class RoomClient {
    protected store: ReduxStore<RootState>;

    protected roomId: string;

    protected peerId: string;

    protected closed: boolean = false;

    /**
     * Our display name.
     */
    protected displayName: string;

    /**
     * Whether we want to produce audio/video.
     */
    protected produce: boolean;

    /**
     * Whether we want to produce audio.
     */
    protected produceAudio: boolean;

    /**
     * Whether we want to consume audio/video.
     */
    protected consume: boolean;

    /**
     * Disable all possible audio features of the recording like noise or echo suppression.
     */
    protected headphoneMode: boolean = false;

    protected preferredCodec: string;

    protected encodings: any;

    protected protooUrl: string;

    protected protoo: protooClient.Peer | null = null;

    protected mediasoupDevice: mediasoupClient.Device | null = null;

    protected sendTransport: mediasoupClient.types.Transport | null = null;

    protected recvTransport: mediasoupClient.types.Transport | null = null;

    protected micProducer: mediasoupClient.types.Producer | null = null;

    protected webcamProducer: mediasoupClient.types.Producer | null = null;

    protected consumers: Map<string, mediasoupClient.types.Consumer<AppData>> = new Map();

    protected webcams: Map<string, MediaDeviceInfo> = new Map();

    protected activeMicId: string | null = null;

    protected directAudio: DirectAudio;


    protected webcam: {
        device: MediaDeviceInfo | null,
        resolution: keyof typeof VIDEO_CONSTRAINTS
    } = {
            device: null,
            resolution: 'fhd'
        };

    constructor({
        reduxStore,
        roomId,
        peerId,
        produce,
        consume,
        preferredCodec,
        encodings
    }: {
        reduxStore: ReduxStore<RootState>,
        roomId: string,
        peerId: string,
        produce: boolean,
        consume: boolean,
        preferredCodec: string,
        encodings: any
    }
    ) {
        this.store = reduxStore;
        this.roomId = roomId;
        this.peerId = peerId;
        this.displayName = localStorage.getItem('displayName') || '';
        this.produce = produce;
        this.produceAudio = true;
        this.consume = consume;
        this.protooUrl = `${ServerUrl}?roomId=${roomId}&peerId=${peerId}`;
        this.directAudio = new DirectAudio(peerId, this.dispatch.bind(this));
        this.preferredCodec = preferredCodec;
        this.encodings = encodings;
    }

    protected dispatch(action: KonferenzAction | NotificationAction) {
        if ('type' in action) {
            this.store.dispatch(action);
        } else {
            (this.store.dispatch as ThunkDispatch<RootState, void, AnyAction>)(action as NotificationAction);
        }
    }

    close() {
        if (this.closed) {
            return;
        }
        this.recvTransport?.close();

        this.dispatch(Actions.setRoomState('closed'));
    }

    async join() {
        log.debug('join()');

        const protooTransport = new protooClient.WebSocketTransport(this.protooUrl);
        this.protoo = new protooClient.Peer(protooTransport);

        this.dispatch(Actions.setRoomState('connecting'));
        this.protoo.on('open', () => this.joinRoom());
        this.protoo.on('failed', () => {
            log.debug('protoo$failed()');
            this.dispatch(Actions.notify(
                'error',
                i18next.t('error.connection.failed')
            ));
        });
        this.protoo.on('disconnected', () => {
            log.debug('protoo$disconnected()');
            this.dispatch(Actions.notify(
                'error',
                i18next.t('error.connection.interrupted')
            ));
            this.sendTransport && this.sendTransport.close();
            this.sendTransport = null;
            this.recvTransport && this.recvTransport.close();
            this.recvTransport = null;

            this.dispatch(Actions.setRoomState('closed'));
        });
        this.protoo.on('close', () => {
            !this.closed && this.close();
        })
        this.protoo.on('request', async (request, accept, reject) => {
            log.debug('protoo$request(method = %s, data = %o)', request.method, request.data);
            switch (request.method) {
                case 'newConsumer':
                    const {
                        peerId,
                        producerId,
                        id,
                        kind,
                        rtpParameters,
                        type,
                        appData,
                        producerPaused
                    } = request.data;

                    try {
                        const consumer = await this.recvTransport!.consume({
                            id,
                            producerId,
                            kind,
                            rtpParameters,
                            appData: { ...appData, peerId }
                        });
                        this.consumers.set(consumer.id, consumer);
                        consumer.on('transportclose', () => {
                            this.consumers.delete(consumer.id);
                        });

                        const {
                            spatialLayers,
                            temporalLayers
                        } = mediasoupClient.parseScalabilityMode(
                            (consumer.rtpParameters.encodings || [])[0].scalabilityMode
                        );

                        log.debug('protoo$request$newConsumer: %i, %i', spatialLayers, temporalLayers);

                        this.dispatch(Actions.addConsumer({
                            id: consumer.id,
                            peerId,
                            type,
                            locallyPaused: false,
                            remotelyPaused: producerPaused,
                            rtpParameters: consumer.rtpParameters,
                            spatialLayers: spatialLayers,
                            temporalLayers: temporalLayers,
                            priority: 1,
                            codec: consumer.rtpParameters.codecs[0].mimeType.split('/')[1],
                            track: consumer.track,
                            score: []
                        }));

                        accept();

                        if (consumer.kind === 'video' && this.store.getState().me.audioOnlyInProgress) {
                            this.pauseConsumer(consumer);
                        }
                    } catch (error) {
                        log.error('newConsumer request failed: %o', error);
                        this.dispatch(Actions.notify(
                            'error',
                            i18next.t('error.peer.consumer-failed', { error })
                        ));
                        throw error;
                    }
                    break;
            }
        });
        this.protoo.on('notification', notification => {
            log.debug('protoo$notification(method = %s, data = %o)', notification.method, notification.data);
            switch (notification.method) {
                case 'producerScore':
                    const { producerId, score } = notification.data;
                    this.store.dispatch(Actions.setProducerScore(producerId, score));
                    break;

                case 'newPeer': {
                    const peer: Types.IPeer = notification.data;

                    this.store.dispatch(Actions.addPeer({
                        ...peer,
                        directAudio: false,
                        producers: [],
                        consumers: []
                    }));

                    this.directAudio.addPeer(peer.id);

                    this.dispatch(Actions.notify(
                        'info',
                        i18next.t('room.joined', { name: peer.displayName })
                    ));
                    break;
                }

                case 'peerClosed': {
                    const { peerId } = notification.data;
                    const peer = this.store.getState().peers.get(peerId);
                    if (!peer) {
                        break;
                    }

                    this.directAudio.removePeer(peerId);
                    this.dispatch(Actions.removePeer(peerId));
                    this.dispatch(Actions.notify(
                        'info',
                        i18next.t('room.left', { name: peer.displayName })
                    ));
                    break;
                }

                case 'peerDisplayNameChanged': {
                    const { peerId, displayName, oldDisplayName } = notification.data;
                    this.dispatch(Actions.setPeerDisplayName(displayName, peerId));
                    this.dispatch(Actions.notify(
                        'info',
                        i18next.t('room.peer-renamed', { oldDisplayName, displayName })
                    ))
                    break;
                }

                case 'consumerClosed': {
                    const { consumerId } = notification.data;
                    const consumer = this.consumers.get(consumerId);;
                    if (!consumer) {
                        break;
                    }

                    const {peerId}: {peerId: string} = consumer.appData;

                    consumer.close();
                    this.consumers.delete(consumerId);
                    this.store.dispatch(Actions.removeConsumer(consumerId, peerId));
                    break;
                }

                case 'consumerPaused': {
                    const { consumerId } = notification.data;
                    const consumer = this.consumers.get(consumerId);;
                    if (!consumer) {
                        break;
                    }

                    consumer.pause();
                    this.dispatch(Actions.setConsumerPaused(consumerId, 'remote'));
                    break;
                }

                case 'consumerResumed': {
                    const { consumerId } = notification.data;
                    const consumer = this.consumers.get(consumerId);;
                    if (!consumer) {
                        break;
                    }

                    consumer.resume();
                    this.dispatch(Actions.setConsumerResumed(consumerId, 'remote'));
                    break;
                }

                case 'consumerScore': {
                    const { consumerId, score } = notification.data;

                    this.dispatch(Actions.setConsumerScore(consumerId, score));
                    break;
                }

                case 'activeSpeaker': {
                    const { peerId } = notification.data;

                    this.dispatch(Actions.setRoomActiveSpeaker(peerId));
                    break;
                }

                case 'consumerLayersChanged': {
                    const { consumerId, spatialLayer, temporalLayer } = notification.data;
                    this.dispatch(Actions.setConsumerCurrentLayers(consumerId, spatialLayer, temporalLayer));
                    break;
                }

                case 'downlinkBwe':
                    break;

                default:
                    log.error('proto#notification: Unknown method %s', notification.method);
            }
        });
    }

    async enableMic(deviceId?: string) {
        log.debug('enableMic()');

        if (this.micProducer) {
            // Mic already enabled.
            return;
        }

        this.dispatch(Actions.setMicInProgress(true));

        if (!this.mediasoupDevice?.canProduce('audio')) {
            log.error('enableMic(): Cannot produce audio');
            this.dispatch(Actions.notify(
                'error',
                i18next.t('error.media.mic.enable')
            ));
        }

        let track: MediaStreamTrack | undefined;

        if (!deviceId) {
            const devices = await navigator.mediaDevices.enumerateDevices();
            deviceId = devices.filter(device => device.kind === 'audioinput')[0].deviceId;
        }
        let stream: MediaStream;
        try {
            stream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    deviceId,
                    ...recordingConstraints[this.headphoneMode ? 'headphone' : 'speaker']
                }
            });
            track = stream.getAudioTracks()[0];

            this.micProducer = await this.sendTransport!.produce({
                track,
                codecOptions: {
                    opusStereo: true,
                    opusFec: true,
                    opusDtx: true,
                    opusMaxAverageBitrate: 256000
                }
            });
            this.directAudio.startEncoding(this.micProducer.track!);

            this.dispatch(Actions.addProducer({
                id: this.micProducer.id,
                deviceLabel: '',
                type: '',
                paused: this.micProducer.paused,
                track: this.micProducer.track!,
                rtpParameters: this.micProducer.rtpParameters,
                codec: this.micProducer.rtpParameters.codecs[0].mimeType.split('/')[1],
                score: []
            }));

            this.micProducer.on('transportclose', () => {
                this.directAudio.stopEncoding();
                this.micProducer = null;
            });
            this.micProducer.on('trackended', () => {
                this.dispatch(Actions.notify(
                    'error',
                    i18next.t('error.media.mic.disconnected')
                ));
                this.disableMic().catch(() => { });
            });

            this.activeMicId = deviceId;
            localStorage.setItem(MIC_ID_STORE, deviceId);
        } catch (error) {
            log.error('enableMic(): Failed: %o', error);
            this.dispatch(Actions.notify(
                'error',
                i18next.t('error.media.mic.activation', { error })
            ));
            track && track.stop();
        }

        this.dispatch(Actions.setMicInProgress(false));
    }

    async disableMic() {
        log.debug('disableMic()');
        if (!this.micProducer) {
            // Mic not active.
            return;
        }
        this.dispatch(Actions.setMicInProgress(true));

        this.directAudio.stopEncoding();
        this.micProducer.track?.stop();
        this.micProducer.close();
        this.dispatch(Actions.removeProducer(this.micProducer.id));

        try {
            await this.protoo!.request('closeProducer', { producerId: this.micProducer.id });
        } catch (error) {
            this.dispatch(Actions.notify(
                'error',
                i18next.t('error.media.mic.disable', error as string)
            ));
        }

        this.micProducer = null;
        this.dispatch(Actions.setMicInProgress(false));
    }

    async getMics(): Promise<MediaDeviceInfo[]> {
        const devices = await navigator.mediaDevices.enumerateDevices();
        return devices.filter(device => device.kind === 'audioinput');
    }

    getActiveMicId(): string | null {
        return this.activeMicId;
    }

    async muteMic() {
        log.debug('muteMic()');
        if (!this.micProducer) {
            // Mic not active.
            return;
        }

        this.dispatch(Actions.setMicInProgress(true));
        this.directAudio.stopEncoding();
        localStorage.setItem(MIC_ID_STORE, 'disabled');
        this.micProducer.pause();

        try {
            await this.protoo!.request('pauseProducer', { producerId: this.micProducer.id });
            this.dispatch(Actions.setProducerPaused(this.micProducer.id));
        } catch (error) {
            this.dispatch(Actions.notify(
                'error',
                i18next.t('error.media.mic.mute', error as string)
            ));
        }
        this.dispatch(Actions.setMicInProgress(false));
    }


    async unmuteMic() {
        log.debug('unmuteMic()');
        if (!this.micProducer) {
            // Mic not active.
            return;
        }

        this.dispatch(Actions.setMicInProgress(true));
        this.micProducer.resume();
        this.directAudio.startEncoding(this.micProducer.track!);

        try {
            await this.protoo!.request('resumeProducer', { producerId: this.micProducer.id });
            this.dispatch(Actions.setProducerResumed(this.micProducer.id));
        } catch (error) {
            this.dispatch(Actions.notify(
                'error',
                i18next.t('error.media.mic.unmute', error as string)
            ));
        }
        this.dispatch(Actions.setMicInProgress(false));
    }

    async enableWebcam(deviceId?: string) {
        log.debug('enableWebcam()');

        if (!this.sendTransport || !this.mediasoupDevice) {
            return;
        }

        if (this.webcamProducer) {
            // Cam already active.
            return;
        }

        let track: MediaStreamTrack | null = null;
        let device: MediaDeviceInfo | null = null;

        this.dispatch(Actions.setWebcamInProgress(true));

        try {
            await this.updateWebcams(deviceId);
            device = this.webcam.device;

            const { resolution } = this.webcam;

            if (!device) {
                throw new Error(i18next.t('error.media.webcam.no-detected'))
            }

            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    deviceId: device.deviceId,
                    ...VIDEO_CONSTRAINTS[resolution]
                }
            });
            track = stream.getVideoTracks()[0];

            const encodings: any = this.encodings;

            log.debug('Codecs: %o', this.mediasoupDevice.rtpCapabilities.codecs);

            const codecName = this.preferredCodec;
            let codec: RtpCodecCapability | undefined =
                (this.mediasoupDevice.rtpCapabilities.codecs || [])
                    .find(c => c.mimeType.toLocaleLowerCase() === codecName);

            if (!codec) {
                throw new Error(i18next.t('error.media.codec-missing', { codec: codecName }));
            }

            const codecOptions = {
                videoGoogleStartBitrate: 1000
            };

            this.webcamProducer = await this.sendTransport.produce({
                track,
                encodings,
                codecOptions,
                codec
            });

            this.dispatch(Actions.addProducer({
                id: this.webcamProducer.id,
                deviceLabel: device.label,
                type: this.getWebcamType(device),
                paused: this.webcamProducer.paused,
                track: this.webcamProducer.track!,
                rtpParameters: this.webcamProducer.rtpParameters,
                codec: this.webcamProducer.rtpParameters.codecs[0].mimeType.split('/')[1],
                score: []
            }));

            this.webcamProducer.on('transportclose', () => this.webcamProducer = null);
            this.webcamProducer.on('trackended', () => {
                this.dispatch(Actions.notify(
                    'error',
                    i18next.t('error.media.webcam.disconnected')
                ));
                this.disableWebcam().catch(() => { });
            });

            localStorage.setItem(WEBCAM_ID_STORE, this.webcam.device!.deviceId);
        } catch (error) {
            this.dispatch(Actions.notify(
                'error',
                i18next.t('error.media.webcam.activation', { error })
            ));
            track && track.stop();
        }

        this.dispatch(Actions.setWebcamInProgress(false));
    }

    async disableWebcam() {
        log.debug('disableWebcam()');

        if (!this.webcamProducer) {
            // Webcam not active.
            return;
        }

        localStorage.setItem(WEBCAM_ID_STORE, 'disabled');
        this.webcamProducer.close();
        this.dispatch(Actions.removeProducer(this.webcamProducer.id));

        try {
            await this.protoo?.request(
                'closeProducer',
                { producerId: this.webcamProducer.id }
            );
        } catch (error) {
            this.dispatch(Actions.notify(
                'error',
                i18next.t('error.media.webcam.deactivation', { error })
            ));
        }
        this.webcamProducer = null;
    }

    async changeWebcam(deviceId: string | undefined = undefined) {
        if (!this.webcamProducer) {
            return;
        }

        this.dispatch(Actions.setWebcamInProgress(true));

        try {
            await this.updateWebcams();
            if (this.webcams.size === 0) {
                throw new Error('error.media.webcam.no-detected');
            }

            if (deviceId && this.webcams.has(deviceId)) {
                this.webcam.device = this.webcams.get(deviceId)!;
            } else if (this.webcam.device) {
                const idArray = Array.from(this.webcams.keys());
                let index = idArray.indexOf(this.webcam.device.deviceId);
                if (index < idArray.length - 1) {
                    index++;
                } else {
                    index = 0;
                }

                this.webcam.device = this.webcams.get(idArray[index])!;
            } else {
                this.webcam.device = this.webcams.values().next().value!;
            }

            this.webcamProducer.track?.stop();

            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    deviceId: { exact: this.webcam.device!.deviceId },
                    ...VIDEO_CONSTRAINTS[this.webcam.resolution]
                }
            });
            const track = stream.getVideoTracks()[0];
            await this.webcamProducer.replaceTrack({ track });
            this.dispatch(Actions.setProducerTrack(this.webcamProducer.id, track));
        } catch (error) {
            this.dispatch(Actions.notify(
                'error',
                i18next.t('error.media.webcam.change', { error })
            ));
        }

        this.dispatch(Actions.setWebcamInProgress(false));
    }

    async getWebcams(): Promise<MediaDeviceInfo[]> {
        const devices = await navigator.mediaDevices.enumerateDevices();
        return devices.filter(device => device.kind === 'videoinput');
    }

    getActiveWebcamId(): string | null {
        return this.webcam.device?.deviceId || null;
    }

    async updateWebcams(deviceId?: string) {
        log.debug('updateWebcams()');
        this.webcams.clear();

        const devices = await navigator.mediaDevices.enumerateDevices();

        for (const device of devices.filter(device => device.kind === 'videoinput')) {
            this.webcams.set(device.deviceId, device);
        }

        const currentWebCamId = deviceId ? deviceId : this.webcam.device?.deviceId;
        if (this.webcams.size === 0) {
            this.webcam.device = null;
        } else if (!currentWebCamId || !this.webcams.has(currentWebCamId)) {
            this.webcam.device = this.webcams.values().next().value
        } else if (this.webcam.device === null) {
            this.webcam.device = this.webcams.get(deviceId!)!;
        }

        this.dispatch(Actions.setCanChangeWebcam(this.webcams.size > 1));
    }

    async enableAudioOnly() {
        this.dispatch(Actions.setAudioOnlyInProgress(true));
        this.disableWebcam();

        for (const consumer of Array.from(this.consumers.values())) {
            consumer.kind === 'video' && this.pauseConsumer(consumer);
        }

        this.dispatch(Actions.setAudioOnlyState(true));
        this.dispatch(Actions.setAudioOnlyInProgress(false));
    }

    async disableAudioOnly() {
        this.dispatch(Actions.setAudioOnlyInProgress(true));
        this.disableWebcam();

        for (const consumer of Array.from(this.consumers.values())) {
            consumer.kind === 'video' && this.resumeConsumer(consumer);
        }

        this.dispatch(Actions.setAudioOnlyState(false));
        this.dispatch(Actions.setAudioOnlyInProgress(false));
    }

    async muteAudio() {
        this.dispatch(Actions.setAudioMutedState(true));
    }

    async unmuteAudio() {
        this.dispatch(Actions.setAudioMutedState(false));
    }

    async setMaxSendingSpacialLayer(layer: number) {
        try {
            this.webcamProducer && this.webcamProducer.setMaxSpatialLayer(layer);
        } catch (error) {
            this.dispatch(Actions.notify(
                'error',
                i18next.t('error.internal', { error })
            ));
        }
    }

    async setConsumerPreferredLayers(consumerId: Types.ConsumerID, spatialLayer: number, temporalLayer: number) {
        try {
            await this.protoo!.request('setConsumerPreferredLayers', { consumerId, spacialLayer: spatialLayer, temporalLayer });
            this.dispatch(Actions.setConsumerPreferredLayers(consumerId, spatialLayer, temporalLayer));
        } catch (error) {
            this.dispatch(Actions.notify(
                'error',
                i18next.t('error.internal', { error })
            ));
        }
    }

    async changeDisplayName(displayName: string) {
        try {
            await this.protoo?.request('changeDisplayName', { displayName });
            this.displayName = displayName;
            this.dispatch(Actions.setDisplayName(displayName));
            this.dispatch(Actions.notify(
                'info',
                i18next.t('room.me-renamed', { displayName })
            ));
            localStorage.setItem('displayName', displayName);
        } catch (error) {
            log.error('changeDisplayName() failed: %o', error);
            this.dispatch(Actions.notify(
                'error',
                i18next.t('error.peer.me-named', { error })
            ));
        }
    }

    getDisplayName(): string {
        return this.displayName;
    }

    setHeadphoneMode(headphoneMode: boolean) {
        this.headphoneMode = headphoneMode;
    }

    async joinRoom() {
        log.debug('joinRoom()');

        try {
            this.mediasoupDevice = new mediasoupClient.Device();
            const routerRtpCapabilities = await this.protoo!.request('getRouterRtpCapabilities');
            await this.mediasoupDevice.load({ routerRtpCapabilities });

            this.dispatch(Actions.setRoomState('connecting'));

            if (this.produce) {
                this.dispatch(Actions.setRoomState('awaiting-media'));

                // Super hack to be able to use audio autoplay. Also used to precheck the recording permission.
                // If the permission is not given, we enter a consumer only mode.
                let stream = null;
                try {
                    const deviceId = localStorage.getItem(MIC_ID_STORE);
                    stream = await navigator.mediaDevices.getUserMedia({
                        audio: {
                            deviceId: (deviceId && deviceId !== 'disabled') ? { exact: deviceId } : undefined,
                            ...recordingConstraints[this.headphoneMode ? 'headphone' : 'speaker']
                        }
                    });
                    const audioTrack = stream.getAudioTracks()[0];
                    audioTrack.enabled = false;
                    // setTimeout(() => audioTrack.stop(), 2000);
                    audioTrack.stop();
                } catch (e) {
                    if ((e as Error).name === 'NotAllowedError') {
                        this.dispatch(Actions.notify(
                            'warning',
                            i18next.t('error.media.mic.notAllowed')
                        ));
                    } else if ((e as Error).name === 'NotReadableError') {
                        (await navigator.mediaDevices.enumerateDevices()).filter(
                            device => device.kind === 'audioinput'
                        ).forEach(async (device) => {
                            log.debug('Trying device %o', device);
                            try {
                                stream = await navigator.mediaDevices.getUserMedia({
                                    audio: {
                                        deviceId: { exact: device.deviceId },
                                        ...recordingConstraints[this.headphoneMode ? 'headphone' : 'speaker']
                                    }
                                });
                            } catch (e) { }
                        });
                        if (stream === null) {
                            log.warn('getUserMedia() in joinRoom(): Could not open any device', e);
                            this.dispatch(Actions.notify(
                                'error',
                                (e as Error).message
                            ));
                        }
                    }
                    if (stream === null) {
                        log.warn('getUserMedia() in joinRoom() gave error: %o', e);
                        this.dispatch(Actions.notify(
                            'warning',
                            i18next.t('error.media.mic.consumerOnly')
                        ));
                        this.produceAudio = false;
                    }
                }

                const transportInfo = await this.protoo!.request(
                    'createWebRtcTransport',
                    {
                        forceTcp: false,
                        producing: true,
                        consuming: false,
                        sctpCapabilities: this.mediasoupDevice.sctpCapabilities
                    }
                );

                const {
                    id,
                    iceParameters,
                    iceCandidates,
                    dtlsParameters,
                    sctpParameters
                } = transportInfo;

                this.sendTransport = this.mediasoupDevice.createSendTransport({
                    id,
                    iceParameters,
                    iceCandidates,
                    dtlsParameters,
                    sctpParameters,
                    iceServers: [],
                    proprietaryConstraints: {
                        optional: [{ googDscp: true }]
                    }
                });

                (window as any).sendTransport = this.sendTransport;

                this.sendTransport.on('connect', ({ dtlsParameters }, callback, errback) => {
                    log.debug('sendTransport$connect()');
                    this.protoo!.request(
                        'connectWebRtcTransport',
                        {
                            transportId: this.sendTransport?.id,
                            dtlsParameters
                        }
                    )
                        .then(callback)
                        .catch(errback);
                });

                this.sendTransport.on('produce', async ({ kind, rtpParameters, appData }, callback, errback) => {
                    log.debug('sendTransport$produce()', rtpParameters);
                    try {
                        const { id } = await this.protoo!.request(
                            'produce',
                            {
                                transportId: this.sendTransport?.id,
                                kind,
                                rtpParameters,
                                appData
                            }
                        );
                        log.debug('sendTransport$produce(): %s', id);
                        callback({ id });
                    } catch (error) {
                        const error_ = error as Error;
                        log.warn('sendTransport$produce(): %o', error);
                        errback(error_);
                    }
                });
            }

            if (this.consume) {
                const transportInfo = await this.protoo!.request(
                    'createWebRtcTransport',
                    {
                        producing: false,
                        consuming: true
                    }
                );

                const {
                    id,
                    iceParameters,
                    iceCandidates,
                    dtlsParameters,
                    sctpParameters
                } = transportInfo;

                this.recvTransport = this.mediasoupDevice.createRecvTransport({
                    id,
                    iceParameters,
                    iceCandidates,
                    dtlsParameters,
                    sctpParameters,
                    iceServers: []
                });

                (window as any).recvTransport = this.recvTransport;

                this.recvTransport.on('connect', ({ dtlsParameters }, callback, errback) => {
                    log.debug('recvTransport$connect()');
                    this.protoo!.request(
                        'connectWebRtcTransport',
                        {
                            transportId: this.recvTransport?.id,
                            dtlsParameters
                        }
                    )
                        .then(callback)
                        .catch(errback);
                });
            }

            const { peers }: { peers: Array<Types.IPeer> } = await this.protoo!.request(
                'join',
                {
                    displayName: this.displayName,
                    rtpCapabilities: this.consume ? this.mediasoupDevice.rtpCapabilities : undefined
                }
            );

            this.dispatch(Actions.setRoomState('connected'));
            this.dispatch(Actions.notify(
                'info',
                i18next.t('room.me-joined')
            ));

            if (peers.length === 0) {
                this.dispatch(Actions.notify(
                    'info',
                    i18next.t('room.still-empty')
                ));
            }

            for (const peer of peers) {
                this.dispatch(Actions.addPeer({
                    ...peer,
                    directAudio: false,
                    consumers: [],
                    producers: []
                }));
                this.directAudio.addPeer(peer.id);
            }

            if (this.produce) {
                this.dispatch(Actions.setMediaCapabilities(
                    this.produceAudio && this.mediasoupDevice.canProduce('audio'),
                    this.mediasoupDevice.canProduce('video')
                ));

                if (this.produceAudio) {
                    if (localStorage.getItem(MIC_ID_STORE) === 'disabled') {
                        this.enableMic().then(() => this.muteMic());
                    } else {
                        this.enableMic(localStorage.getItem(MIC_ID_STORE) || undefined);
                    }
                }
                if (localStorage.getItem(WEBCAM_ID_STORE) !== 'disabled') {
                    this.enableWebcam(localStorage.getItem(WEBCAM_ID_STORE) || undefined);
                }
            }
        } catch (error) {
            log.error('Error joining room: %o', error);
            this.dispatch(Actions.notify(
                'error',
                i18next.t('error.connection.room-join', { error })
            ));
            this.close();
        }
    }

    getWebcamType(device: MediaDeviceInfo): WebCamType {
        if (/(back|rear)/i.test(device.label)) {
            return 'back';
        }
        return 'front'
    }

    async pauseConsumer(consumer: mediasoupClient.types.Consumer) {
        if (consumer.paused) {
            return;
        }

        try {
            await this.protoo!.request('pauseConsumer', { consumerId: consumer.id });
            consumer.pause();
            this.dispatch(Actions.setConsumerPaused(consumer.id, 'local'));
        } catch (error) {
            this.dispatch(Actions.notify(
                'error',
                i18next.t('error.internal', { error })
            ));
        }
    }

    async resumeConsumer(consumer: mediasoupClient.types.Consumer) {
        if (!consumer.paused) {
            return;
        }

        try {
            await this.protoo!.request('resumeConsumer', { consumerId: consumer.id });
            consumer.pause();
            this.dispatch(Actions.setConsumerResumed(consumer.id, 'local'));
        } catch (error) {
            this.dispatch(Actions.notify(
                'error',
                i18next.t('error.internal', { error })
            ));
        }
    }
};
