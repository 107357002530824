import { combineReducers } from "redux";
import consumers from './consumers';
import me from './me';
import notifications from './notifications';
import peers from './peers';
import producers from './producers';
import room from './room';

export default combineReducers({
    consumers,
    me,
    notifications,
    peers,
    producers,
    room
});
